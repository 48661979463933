import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "マネーフォワードさん・ Sansan さんとエンジニア勉強会を開催しました",
  "date": "2016-05-23T01:11:56.000Z",
  "slug": "entry/2016/05/23/101156",
  "tags": ["medley"],
  "hero": "./2016_05_23.png",
  "heroAlt": "勉強会"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`5/11（水）、マネーフォワード・ Sansan と共催で勉強会を開催しました。`}</p>
    <p>{`120 人もの応募をいただいた本イベント、少しだけ当日の様子をレポートします！`}</p>
    <p><a parentName="p" {...{
        "href": "https://connpass.com/event/29430/"
      }}><img parentName="a" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160520/20160520175219.png",
          "alt": "f:id:medley_inc:20160520175219p:plain",
          "title": "f:id:medley_inc:20160520175219p:plain"
        }}></img></a></p>
    <p>{`金融、医療、HR、営業管理など、IT が十分に活用されてこなかった、ともすれば「固い」と思われがちな業界を IT で変えていく、という共通点で集まった 3 社が、チーム作りや開発のノウハウをお話しました。`}</p>
    <p>{`メドレーでは、まずは CTO の平山から弊社のサービスやチームをご紹介。医師とエンジニアが連携した独自の体制についてお話させていただきました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160520/20160520180104.jpg",
        "alt": "f:id:medley_inc:20160520180104j:plain",
        "title": "f:id:medley_inc:20160520180104j:plain"
      }}></img></p>
    <p>{`開発部の宮内からは、オンライン通院システム「CLINICS（クリニクス）」の開発の裏側についてお話しました。3 月に開催したイベントでお話した内容を受けて「前回のあらすじ」から始まる斬新なプレゼンに、会場が沸きました（笑）。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160523/20160523100028.jpg",
        "alt": "f:id:medley_inc:20160523100028j:plain",
        "title": "f:id:medley_inc:20160523100028j:plain"
      }}></img></p>
    <iframe id="talk_frame_341438" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/2469dc49d5ba434fb744dcc2be63d412" width="710" height="463" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/onraintong-yuan-sisutemu-clinics-kurinikusu-falsewu-tai-li-wozhi-eruji-shu-jia">speakerdeck.com</a></cite>
    <p>{`稲本からは、医療介護の求人サイト「ジョブメドレー」の開発についてご紹介しました。企業の採用情報のインフラとして、誰でも使える・信頼性の高いサイトをつくるための開発体制などをお話しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160523/20160523100247.jpg",
        "alt": "f:id:medley_inc:20160523100247j:plain",
        "title": "f:id:medley_inc:20160523100247j:plain"
      }}></img></p>
    <iframe id="talk_frame_341442" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/8afd44b365574855a13531511b8a3b87" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/yi-liao-jie-hu-qiu-ren-saito-ziyobumedore-falsekai-fa-shi-qing">speakerdeck.com</a></cite>
    <p>{`マネーフォワードさんからは、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`を使っている開発の日常についてご紹介があったり、Sansan さんからは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C6%C1%C5%E7%B8%A9"
      }}>{`徳島県`}</a>{`の Sansan 神山ラボに山ごもりしての開発合宿の様子など、各社の特色が現れた LT が展開されました。`}</p>
    <p>{`◆ マネーフォワードさんの LT はこちら`}</p>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/yui_knk/rubykomituta-railskomituta-gairuri-chang"
      }}>{`"Ruby コミッター" "Rails コミッター"がいる日常 // Speaker Deck`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/toru_taniguchi/btobpurodakutochuang-riniokeruti-zhi-toda-qie-nisiteirukoto"
      }}>{`BtoB プロダクト創りにおける体制と大切にしていること // Speaker Deck`}</a></p>
    <p>{`◆Sansan さんの LT はこちら`}</p>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/dotrikun/sumatoshan-gomorideapuriwogao-su-kai-fa-suru"
      }}>{`スマート山ごもりでアプリを高速開発する // Speaker Deck`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/ynakagawa33/task-force-20-percent-time-at-sansan"
      }}>{`Task Force ~ 20 percent time@Sansan ~ // Speaker Deck`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/sigemoto/purodakutomanezimentofalseusohonto"
      }}>{`プロダクトマネジメントのウソ・ホント // Speaker Deck`}</a></p>
    <p>{`お待ちかねの懇親会！色とりどりの料理にがっつく一同…（笑）`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160520/20160520182949.jpg",
        "alt": "f:id:medley_inc:20160520182949j:plain",
        "title": "f:id:medley_inc:20160520182949j:plain"
      }}></img></p>
    <p>{`異業種 3 社が集まったイベントということもあり、参加者の属性もさまざま。参加者から登壇者にむけた LT への質問はもちろん、お互いの開発ノウハウなどを情報交換し、とても盛り上がる時間となりました。`}</p>
    <p>{`メドレーでは今後も、エンジニアの勉強会・交流会を積極的に開催します。`}</p>
    <p>{`開催情報は`}<a parentName="p" {...{
        "href": "https://www.facebook.com/medley.jp/"
      }}>{`Facebook`}</a>{`などでお知らせしておりますので、ぜひチェックしてみてください！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      